import React, { useState, useEffect } from 'react';
import style from './extradata.module.scss';

const ExtraVehicleData = () => {
  const [vehicleData, setVehicleData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const pathParts = window.location.pathname.split('/');
        const vin = pathParts[pathParts.length - 1] || pathParts[pathParts.length - 2];
        const response = await fetch(`https://rechargeddata.mxssolutions.com/vehicle/${vin}/json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
  
        if (data.DriverAssistanceTechnology_Included) {
          data.DriverAssistanceTechnology_Included = data.DriverAssistanceTechnology_Included.split(',').map(tech => tech.trim().toLowerCase());
        }
        if (data.DriverAssistanceTechnology_Available) {
          data.DriverAssistanceTechnology_Available = data.DriverAssistanceTechnology_Available.split(',').map(tech => tech.trim().toLowerCase());
  
          // Remove common values between Included and Available
          data.DriverAssistanceTechnology_Available = data.DriverAssistanceTechnology_Available.filter(
            tech => !data.DriverAssistanceTechnology_Included.includes(tech)
          );
        }
  
        setVehicleData(data);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }
  
    fetchData();
  }, []);
  
  const renderIncludedOptionImage = (technology, imagePath, altText) => {
    if (!vehicleData || !vehicleData.DriverAssistanceTechnology_Included) return null;
  
    const formattedTechnology = technology.trim().toLowerCase();
  
    if (vehicleData.DriverAssistanceTechnology_Included.includes(formattedTechnology)) {
      return <img key={formattedTechnology} src={imagePath} alt={altText} />;
    } else {
      return null;
    }
  };
  
  const renderAvailableOptionImage = (technology, imagePath, altText) => {
    if (!vehicleData || !vehicleData.DriverAssistanceTechnology_Available) return null;
  
    const formattedTechnology = technology.trim().toLowerCase();
  
    if (vehicleData.DriverAssistanceTechnology_Available.includes(formattedTechnology)) {
      return <img key={formattedTechnology} src={imagePath} alt={altText} />;
    } else {
      return null;
    }
  };
  

  if (!vehicleData) {
    return <div></div>;
  }

  return (
    <div>
      <p className={style['justTheFacts']}>Vehicle Details</p>
      <div className={style['vehicleDetails']}>
        {vehicleData.RealRange && (
          <div>
            <img src="https://media.dealermasters.com/Recharged/icons/RealRangex3.webp" alt="Real Range" />
            <p>Real Range: <span>{vehicleData.RealRange} mi.</span></p>
          </div>
        )}
        {vehicleData.EPARange && (
            <div>
                <img src="https://media.dealermasters.com/Recharged/icons/EPARangex3.webp" alt="EPA Range" />
                <p>EPA Range: <span>{vehicleData.EPARange} mi.</span></p>
            </div>
        )}
        {vehicleData.DCFCChargeTime_minutes && (
            <div>
                <img src="https://media.dealermasters.com/Recharged/icons/FastChargeTimex3.webp" alt="FastChargeTime" />
                <p className={style['chargeDisclaimer']}>DC Fast Charge Time: <span>{vehicleData.DCFCChargeTime_minutes} min.</span></p>
            </div>
        )}
        {vehicleData.Level2ChargeTime_hours && (
            <div>
                <img src="https://media.dealermasters.com/Recharged/icons/L2ChargeTimex3.webp" alt="L2ChargeTime" />
                <p className={style['chargeDisclaimer']}>Level 2 Charge Time: <span>{vehicleData.Level2ChargeTime_hours} hrs.</span></p>
            </div>
        )}
        {vehicleData.BasicWarranty && (
            <div className={style['warrantyIcon']}>
                <img src="https://media.dealermasters.com/Recharged/icons/BasicWarrantyx3.webp" alt="Basic Warranty" />
                <p><span>{vehicleData.BasicWarranty}</span></p>
            </div>
        )}
        {vehicleData.BatteryWarranty && (
           <div className={style['warrantyIcon']}>
            <img src="https://media.dealermasters.com/Recharged/icons/BatteryWarrantyx3.webp" alt="Battery Warranty" />
                <p><span>{vehicleData.BatteryWarranty}</span></p>
            </div>
        )}
        {vehicleData && (
            <div className={style['mobileCharging']}>
                <img src="https://media.dealermasters.com/Recharged/icons/MobileChargingx3.webp" alt="Keys" />
                <p><span>Mobile Charging Bundle Included</span><a href="/purchase-installation/">View Level 2 Charger Purchase Options</a></p>
            </div>
        )}
        {vehicleData.Keys && (
            <div>
                <img src="https://media.dealermasters.com/Recharged/icons/KeysIconx3.webp" alt="Keys" />
                <p><span>{vehicleData.Keys}</span></p>
            </div>
        )}
      </div>
      <div className={style["keyFeatures"]}>
        <p>Key Features:</p>
        <div>
          {renderIncludedOptionImage('super_cruise', 'https://media.dealermasters.com/Recharged/icons/TrustingSoul.webp', 'Super Cruise')}
          {renderIncludedOptionImage('heated_seats', 'https://media.dealermasters.com/Recharged/icons/WarmButt.webp', 'Heated Seats')}
          {renderIncludedOptionImage('cooled_seats', 'https://media.dealermasters.com/Recharged/icons/CoolButt.webp', 'Cooled Seats')}
          {renderIncludedOptionImage('adaptive_cruise_control', 'https://media.dealermasters.com/Recharged/icons/AdaptiveCruise.webp', 'Adaptive Cruise Control')}
          {renderIncludedOptionImage('apple_carplay / android_auto', 'https://media.dealermasters.com/Recharged/icons/AppleAndroidAudio.webp', 'Apple CarPlay / Android Auto')}
          {renderIncludedOptionImage('blind_spot_monitoring', 'https://media.dealermasters.com/Recharged/icons/BlindSpot.webp', 'Blind Spot Monitoring')}
          {renderIncludedOptionImage('collision_safety_system', 'https://media.dealermasters.com/Recharged/icons/CollisionWarning.webp', 'Collision Safety System')}
          {renderIncludedOptionImage('driver_attention', 'https://media.dealermasters.com/Recharged/icons/DriverAttention.webp', 'Driver Attention')}
          {renderIncludedOptionImage('fast_charging', 'https://media.dealermasters.com/Recharged/icons/FastCharging.webp', 'Fast Charging')}
          {renderIncludedOptionImage('heated_steering_wheel', 'https://media.dealermasters.com/Recharged/icons/HeatedSteering.webp', 'Heated Steering Wheel')}
          {renderIncludedOptionImage('full_self_driving', 'https://media.dealermasters.com/Recharged/icons/NextOnRidiculousness.webp', 'Full Self Driving')}
          {renderIncludedOptionImage('autopilot', 'https://media.dealermasters.com/Recharged/icons/DarwinAward.webp', 'Autopilot')}
          {renderIncludedOptionImage('lane_assist', 'https://media.dealermasters.com/Recharged/icons/LaneAssist.webp', 'Lane Assist')}
          {renderIncludedOptionImage('leather', 'https://media.dealermasters.com/Recharged/icons/Leather.webp', 'Leather')}
          {renderIncludedOptionImage('navigation', 'https://media.dealermasters.com/Recharged/icons/Navigation.webp', 'Navigation')}
          {renderIncludedOptionImage('parking_assist', 'https://media.dealermasters.com/Recharged/icons/ParkingAssist.webp', 'Parking Assist')}
          {renderIncludedOptionImage('sunroof / moonroof', 'https://media.dealermasters.com/Recharged/icons/SunroofMoonroof.webp', 'Sunroof / Moonroof')}
        </div>
      </div>
      {vehicleData.DriverAssistanceTechnology_Available && (
        <div className={style["keyFeatures"]}>
          <p>Available Driver Assistance Technology</p>
          <p id="DATp">These features are available for purchase or paid monthly subscription from the manufacturer.</p>
          <div>
          {renderAvailableOptionImage('full_self_driving', 'https://media.dealermasters.com/Recharged/icons/NextOnRidiculousness.webp', 'Full Self Driving')}
          {renderAvailableOptionImage('autopilot', 'https://media.dealermasters.com/Recharged/icons/DarwinAward.webp', 'Autopilot')}
          </div>
        </div>
      )
      }
    </div>
  );
};

export default ExtraVehicleData;
