import React from "react"
import Vehicle from "../../templates/Vehicle"
import style from "./vdp.module.scss"
import mileageImage from "../../../static/speedometer-icon2.png"
import range_icon from '../featured/range_icon.png'
import RechargedRating from '../recommendation/dealerRating'
import view from "../featured/featured.module.scss"
import ExtraVehicleData from "../extraData/ExtraVehicleData"
import ReportPopup from '../extraData/reportPopup';
import ReportPopupSRPRevamp from "../extraData/reportPopupSRPRevamp"

export default function VDPAbout({ vehicle }) {
  const { VehicleInfo } = vehicle

  return (
    <section className={style["about"]}>
      <div className={style["inventory_list"]}>
        {/* <figcaption className={style["inventory-list__figcaption"]}>
          {(VehicleInfo.hasOwnProperty("ExteriorColor")) && (
            <>
              <span className={style["inventory-list__figcaption-name"]}>
                Exterior color:
              </span>
              <span className={style["inventory-list__figcaption-value"]}>
                &nbsp;{VehicleInfo.ExteriorColor}
              </span>
            </>
          )}
        </figcaption>
        <figcaption className={style["inventory-list__figcaption"]}>
          {(VehicleInfo.hasOwnProperty("InteriorColor")) && (
            <>
              <span className={style["inventory-list__figcaption-name"]}>
                Interior color:
              </span>
              <span className={style["inventory-list__figcaption-value"]}>
                &nbsp;{VehicleInfo.InteriorColor}
              </span>
            </>
          )}
        </figcaption> */}
        <div className={style['vdpFlex']}>
            {/* <div className={style["range_cont"]}>
              <img src={range_icon} alt="EPA Estimated Range" />
              {vehicle.VehicleInfo.Comments5 && Number(VehicleInfo.Comments5) > 0 ? (
                        <div className={view["range_info"]}>
                          <div>
                            <p className={view["range_info_text"]}>Range</p>
                            <p className={view["range_info_text"]}>(EPA Est.)</p>
                          </div>
                          <div><p className={view["range_info_text_value"]}> {(vehicle.VehicleInfo.Comments5.toLocaleString())} mi.</p></div>
                        </div>
                        ) : <p className={view["range_info_text_value2"]}>Range Pending</p>}
            </div>   */}
                    <div className={view["range_cont"]}>
                        {/* <a href="#">
                            <img id={view["reportImg"]} src="https://media.dealermasters.com/Recharged/icons/reportIconx3.webp" alt="view the recharged report" /> view recharged score report
                        </a> */}
                        { vehicle.VehicleInfo.Comments4 > 0 &&
                        <ReportPopupSRPRevamp vin={vehicle.VehicleInfo.VIN}/>}
                        
                    </div>  
          </div>
                    <RechargedRating rating={vehicle.VehicleInfo.Comments4} />
                    <a className={style["carfaxLink"]} href={vehicle.VehicleInfo.VehicleHistoryReportLink} target="_blank">
                            <img style={{ maxHeight: '150px', maxWidth: '225px'}} src={vehicle.VehicleInfo.Category5} />
                    </a>
      </div>
      {/* <p className={style["inventory-list__specs"]}>
        {`
          ${VehicleInfo.Drivetrain.length > 0 ? VehicleInfo.Drivetrain + ',': ''}
          ${VehicleInfo.Transmission.length > 0 ? VehicleInfo.Transmission + ',': ''} 
          ${VehicleInfo.Engine.length > 0 ? VehicleInfo.Engine + ',': ''} 
          ${VehicleInfo.CityMPG > 0 || VehicleInfo.HwyMPG > 0? VehicleInfo.CityMPG + '/' + VehicleInfo.HwyMPG + ' City/Highway MPG': ''} 
        `}
      </p> */}
      <ExtraVehicleData />
    </section>
  )
}
